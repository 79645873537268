import React from 'react'
import HeaderImageBar from '../headerImageBar'


const Home = () => {
    return (
        <>
           <HeaderImageBar pageName="Home" />
        </>
    );
};
export default Home;