import React from 'react';
import {
    Card,
    StyledBody,
    StyledAction
  } from "baseui/card";
  import { Button } from "baseui/button";
import { Label3, Display4 } from 'baseui/typography';
import HeaderImageBar from '../headerImageBar'
import {Link} from 'react-router-dom'
const projectList = [
]

const Projects = () => {
    return (
        <>
            <HeaderImageBar 
                pageName="Projects" 
                PageLinks={
                    <Label3 className="headerImageTextStyle">
                        <Link className="headerImageLinks" to="/">Home</Link>
                         / <Link className="headerImageLinks" to="projects">Projects</Link>
                    </Label3>}
            />
            <div className="pageContent">
            <Card>
                <Display4>
                    Projects
                </Display4>
                <div className="projectList">
                    {
                        projectList.length === 0 &&
                        <p>Currently No Projects</p>
                    }
                    {
                        projectList.length > 0 &&
                        <Card
                            overrides={{Root: {style: {width: '328px'}}}}
                            headerImage={'https://source.unsplash.com/user/erondu/700x400'}
                            title="Example card"
                        >
                            <StyledBody>
                            Proin ut dui sed metus pharetra hend rerit vel non mi. Nulla
                            ornare faucibus ex, non facilisis nisl.
                            </StyledBody>
                            <StyledAction>
                            <Button overrides={{BaseButton: {style: {width: '100%'}}}}>
                                Button Label
                            </Button>
                            </StyledAction>
                        </Card>
                    }
                </div>
            </Card>
            </div>
        </>
    );
};
export default Projects;