import React from 'react';
import { Button, KIND, SIZE } from "baseui/button";
import {
    StyledNavigationItem as NavigationItem,
  } from 'baseui/header-navigation';
import {
    Card,
  } from "baseui/card";
import { Label3 } from 'baseui/typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faFacebookF, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

const socialIcons = [
    {
        icon: faGithub,
        link: 'https://github.com/eboaden'
    },
    {
        icon: faLinkedin,
        link: 'https://linkedin.com/in/edwardboaden'
    },
    {
        icon: faEnvelope,
        link: 'mailto:me@edward.tech'
    }
]

const Footer = () => {
return(
    <Card style={{boxShadow:'none', borderWidth:0, padding:'20px',textAlign:'center'}}>
        <div className="socialFooterIcons">
            {
                socialIcons.map(social => (
                    <NavigationItem className="socialFooterIcon">
                        <a href={social.link} target="_blank" rel="noopener noreferrer">
                            <Button
                            kind={KIND.tertiary}
                            size={SIZE.compact}
                            >
                            <FontAwesomeIcon icon={social.icon} />
                            </Button>
                        </a>
                    </NavigationItem>
                ))
            }
            </div>
        <br />
        <div className="footerText">
            <Label3>&copy; Edward Boaden {(new Date().getFullYear())}</Label3>
        </div>
    </Card>
)}

export default Footer
