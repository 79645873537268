import React from 'react';
import {
    Card,
  } from "baseui/card";
import { Label3, Display4 } from 'baseui/typography';
import HeaderImageBar from '../headerImageBar'
import {Link} from 'react-router-dom'


const About = ({history}) => {
    return (
        <>
            <HeaderImageBar 
                pageName="About" 
                PageLinks={
                    <Label3 className="headerImageTextStyle">
                        <Link className="headerImageLinks" to="/">Home</Link>
                         / <Link className="headerImageLinks" to="about">About</Link>
                    </Label3>}
            />
            <div className="pageContent">
            <Card>
                <Display4>
                    About
                </Display4>
                <p>Put Some Content Here</p>
                
            </Card>
            </div>
        </>
    );
};
export default About;
