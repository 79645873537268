import React from 'react';
import {
    HeaderNavigation,
    ALIGN,
    StyledNavigationItem as NavigationItem,
    StyledNavigationList as NavigationList,
  } from 'baseui/header-navigation';
  import {
    Label1,
    Label4,
  } from 'baseui/typography';
import {Link} from 'react-router-dom';
import { Button, KIND, SIZE } from "baseui/button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import isMobile from '../../utils/isMobile'
import { Drawer, ANCHOR } from "baseui/drawer";

const menuItems = [
    {
        content: <><Label1>Edward Boaden</Label1><Label4>Software Engineer</Label4></>,
        link: '/',
        type: 'mainHeader',
        hasImage: false,
        image: null
    },
    /*{
        content: 'About',
        link: '/about',
        type: 'navItem',
        hasImage: false,
        image: null
    },
    {
        content: 'Projects',
        link: '/projects',
        type: 'navItem',
        hasImage: false,
        image: null
    }, */
    {
        content: 'Contact',
        link: '/contact',
        type: 'navItem',
        hasImage: false,
        image: null
    },
    {
        content: 'Github',
        link: 'https://github.com/eboaden',
        type: 'button',
        hasImage: true,
        image: faGithub,
        className: 'githubButton'
    },
]

const Header = () => {
    const [isOpen, setIsOpen] = React.useState(false);
    return (
        <>
        <HeaderNavigation className='headerBar'>
            <NavigationList $align={ALIGN.left}>
                {
                    menuItems.filter(x => x.type === 'mainHeader').map(item => (
                        <NavigationItem>
                            <Link to={item.link}>
                            {item.content}
                            </Link>
                        </NavigationItem>
                    ))
                }
            </NavigationList>
            <NavigationList $align={ALIGN.center} />
            <NavigationList $align={ALIGN.right}>
            {
                !isMobile &&
                <>
                {
                    menuItems.filter(x => x.type === 'navItem').map(item => (
                        <NavigationItem>
                            <Link to={item.link}>
                                <Button
                                kind={KIND.tertiary}
                                size={SIZE.compact}
                                >
                                {item.content}
                                </Button>
                            </Link>
                        </NavigationItem>
                    ))
                }
                {
                    menuItems.filter(x => x.type === 'button').map(item => (
                        <NavigationItem className={item.className}>
                            <a href={item.link} target="_blank" rel="noopener noreferrer">
                                <Button>{item.hasImage ? <FontAwesomeIcon icon={item.image} /> : ''}&nbsp;&nbsp;{item.content}</Button>
                            </a>
                        </NavigationItem>
                    ))
                }
                </>
            }
            {
                isMobile &&
                <NavigationItem>
                        <Button
                        kind={KIND.tertiary}
                        size={SIZE.compact}
                        onClick={() => setIsOpen(true)}
                        >
                        <span style={{fontSize:'22px'}}>&#9776;</span>
                        </Button>
                </NavigationItem>
            }
            </NavigationList>
        </HeaderNavigation>
        <Drawer
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        size={SIZE.full}
        anchor={ANCHOR.left}
        >
        <>
                {
                    menuItems.filter(x => x.type === 'mainHeader').map(item => (
                        <>
                        <NavigationItem onClick={() => setIsOpen(false)}>
                            <Link to={item.link}>
                            {item.content}
                            </Link>
                        </NavigationItem>
                        <br />
                        </>
                    ))
                }
                {
                    menuItems.filter(x => x.type === 'navItem').map(item => (
                        <>
                        <NavigationItem>
                            <Link to={item.link}>
                                <Button
                                kind={KIND.tertiary}
                                size={SIZE.compact}
                                onClick={() => setIsOpen(false)}
                                >
                                {item.content}
                                </Button>
                            </Link>
                        </NavigationItem>
                        <br />
                        </>
                    ))
                }
                {
                    menuItems.filter(x => x.type === 'button').map(item => (
                        <NavigationItem className={item.className} onClick={() => setIsOpen(false)}>
                            <a href={item.link} target="_blank" rel="noopener noreferrer">
                                <Button>{item.hasImage ? <FontAwesomeIcon icon={item.image} /> : ''}&nbsp;&nbsp;{item.content}</Button>
                            </a>
                        </NavigationItem>
                    ))
                }
                </>
        </Drawer>
        </>
    );
};
export default Header;
