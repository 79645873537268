import React from 'react';
import HeaderImage from '../../assets/mac-coffee-photo.jpg'
import HeaderPageImage from '../../assets/mac-coffee-photo.jpg'
import isMobile from '../../utils/isMobile'
import {
    Display4,
  } from 'baseui/typography';


const HeaderImageBar = ({history, pageName, PageLinks}) => {
    return (
        <div width={'100%'}>
            {
                !isMobile &&
                (pageName === "Home" ?
                    <img src={HeaderImage} className="headerImage" alt="Header Bar" />
                :
                    <div className="headerPageContainer">
                        <img src={HeaderPageImage} className="headerPageImage" alt="Header Bar" />
                        <div class="headerImageText">
                            <Display4 style={{color:'white'}}>{pageName}</Display4>
                            <br />
                            {PageLinks}
                        </div>
                    </div>)
            }
            {
                isMobile &&
                (pageName === "Home" ?
                    <img src={HeaderImage} className="headerImage" alt="Header Bar" />
                :
                    <div className="headerPageContainer">
                        <img src={HeaderImage} className="headerPageImage" alt="Header Bar" />
                        <div class="headerImageText">
                            <Display4 style={{color:'white'}}>{pageName}</Display4>
                            <br />
                            {PageLinks}
                        </div>
                    </div>)
            }   
        </div>
    );
};
export default HeaderImageBar;