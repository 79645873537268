import React, {useState} from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {LightTheme, DarkTheme, BaseProvider} from 'baseui';

import Header from './components/header'
import Footer from './components/footer'
import Home from './components/home'
import About from './components/about'
import Projects from './components/projects'
import Contact from './components/contact'
import ExternalRedirect from './components/ExternalRedirect'

function setColorScheme(setIsDarkMode,isCurrentDarkMode) {
  const isDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches
  const isLightMode = window.matchMedia("(prefers-color-scheme: light)").matches
  const isNotSpecified = window.matchMedia("(prefers-color-scheme: no-preference)").matches
  const hasNoSupport = !isDarkMode && !isLightMode && !isNotSpecified;

  window.matchMedia("(prefers-color-scheme: dark)").addListener(e => e.matches && setIsDarkMode(true))
  window.matchMedia("(prefers-color-scheme: light)").addListener(e => e.matches && setIsDarkMode(false))

  if(isDarkMode && !isCurrentDarkMode) setIsDarkMode(true)
  if(isLightMode && isCurrentDarkMode) setIsDarkMode(false)
  if(isNotSpecified || hasNoSupport) {
    const now = new Date();
    const hour = now.getHours();
    if (hour < 4 || hour >= 16) {
      setIsDarkMode(true)
    }
  }
}

const App = () => {
  const [isDarkMode, setIsDarkMode] = useState(true)
  setColorScheme(setIsDarkMode,isDarkMode)
  return (
    <div className={isDarkMode ? 'backgroundDark': 'backgroundLight'}>
      <BaseProvider theme={isDarkMode ? DarkTheme : LightTheme}>
        <Router>
            <Header/>
              <Switch>
                <Route exact path="/" component={Home}/>
                <Route path="/about" component={About}/>
                <Route path="/projects" component={Projects}/>
                <Route path="/contact" component={Contact}/>
                <Route path="/pay">
                  <ExternalRedirect url="https://settleup.starlingbank.com/edwardboaden" />
                </Route>
              </Switch>
            <Footer theme={isDarkMode ? DarkTheme : LightTheme} darkMode={isDarkMode} />
        </Router>
      </BaseProvider>
    </div>
  );
}

export default App;
