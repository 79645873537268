import React from 'react';
import {
    Card,
  } from "baseui/card";
import HeaderImageBar from '../headerImageBar'
import {Link} from 'react-router-dom'
import { Label2, Label3, Display4 } from 'baseui/typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faGithub, faTwitter, faInstagram, faLinkedin, faFacebookF } from '@fortawesome/free-brands-svg-icons'

const contactLinks = [
    {
        name: "Email",
        username: "me@edward.tech",
        link: "mailto:me@edward.tech",
        icon: faEnvelope,
    },
    {
        name: "Github",
        username: "@eboaden",
        link: "https://github.com/eboaden",
        icon: faGithub,
    },
    {
        name: "Linked In",
        username: "@edwardboaden",
        link: "https://linkedin.com/in/edwardboaden",
        icon: faLinkedin,
    }
]

const Contact = ({history}) => {
    return (
        <>
            <HeaderImageBar 
                pageName="Contact" 
                PageLinks={
                    <Label3 className="headerImageTextStyle">
                        <Link className="headerImageLinks" to="/">Home</Link>
                         /
                         <Link className="headerImageLinks" to="contact">Contact</Link>
                    </Label3>}
            />
           <div className="pageContent">
            <Card>
                <Display4>
                    Contact
                </Display4>
                <br />
                <div className="contactItems">
                    {
                        contactLinks.map(contact => (
                            <div className="contactItem">
                                <Label2><FontAwesomeIcon icon={contact.icon} />&nbsp;&nbsp;{contact.name} - <a href={contact.link} target="_blank" rel="noopener noreferrer" className="contactItemLinks">{contact.username}</a></Label2>
                            </div>
                        ))
                    }
                </div>
            </Card>
            </div>
        </>
    );
};
export default Contact;
